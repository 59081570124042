import {createAction, props} from '@ngrx/store';
import {ProductCategory as Entity} from './product-category.model';
import {IPage, ITableSort, IResultWithPageInfo, IOrderResult} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";
import {TemplateGraphic} from "../template-graphic/template-graphic.model";
import {ProductCategoryExtendedData} from "../../interfaces/ExtendedData";
import {Template} from "../template/template.model";

export const getRootCategory = createAction(
  '[ProductCategory] Get root category',
  props<{ productCategoryId: number, correlationId?: string }>()
);

export const getRootCategorySuccess = createAction(
  '[ProductCategory] Get root category Success',
  props<{ productCategoryId: number, correlationId?: string }>()
);

export const getRootCategoryFailure = createAction(
  '[ProductCategory] Get root category Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadRelatedHierarchy = createAction(
  '[ProductCategory] Load related Hierarchy',
  props<{ productCategoryId: number, correlationId?: string }>()
);

export const loadRelatedHierarchySuccess = createAction(
  '[ProductCategory] Load related Hierarchy Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadRelatedHierarchyFailure = createAction(
  '[ProductCategory] Load related Hierarchy Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadNavigationHierarchy = createAction(
  '[ProductCategory] Load Navigation Hierarchy',
  props<{ productCategoryId: number, correlationId?: string }>()
);

export const loadNavigationHierarchySuccess = createAction(
  '[ProductCategory] Load Navigation Hierarchy Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadNavigationHierarchyFailure = createAction(
  '[ProductCategory] Load Navigation Hierarchy Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const removeLastNavigationHierarchy = createAction(
  '[ProductCategory] Remove Last Navigation Hierarchy'
);

export const clearNavigationHierarchy = createAction(
  '[ProductCategory] Clear Navigation Hierarchy'
);

export const removeNavigationHierarchy = createAction(
  '[ProductCategory] Remove Navigation Hierarchy',
  props<{ id: number }>()
);

export const addNavigationHierarchy = createAction(
  '[ProductCategory] Add Navigation Hierarchy',
  props<{ id: number, name: string, accessId: number, resourceId: string, hasChildren: boolean }>()
);

export const sliceNavigationHierarchy = createAction(
  '[ProductCategory] Slice Navigation Hierarchy',
  props<{ id: number }>()
);

export const clear = createAction(
  '[ProductCategory] Clear Entities'
);

export const clearPage = createAction(
  '[ProductCategory] Clear Page'
);

export const clearRelatedProductCategories = createAction(
    '[ProductCategory] Clear Page for Related Categories'
);

export const clone = createAction(
  '[ProductCategory] Clone Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[ProductCategory] Clone Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[ProductCategory] Clone Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadIfNecessary = createAction(
  '[ProductCategory] Load Templates If Necessary',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccessIfNecessary = createAction(
  '[ProductCategory] Load Templates Success If Necessary',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailureIfNecessary = createAction(
  '[ProductCategory] Load Templates Failure If Necessary',
  props<{ error: Error, correlationId?: string }>()
);


export const load = createAction(
  '[ProductCategory] Load Templates',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[ProductCategory] Load Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[ProductCategory] Load Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[ProductCategory] Load Single Template if necessary',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[ProductCategory] Load Single Template',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[ProductCategory] Load Single Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[ProductCategory] Load Single Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const clearSelectedForClone = createAction(
    '[ProductCategory] Clear selected For Clone Product Categories'
);

export const selectForClone = createAction(
    '[ProductCategory] Select For Clone Product Categories',
    props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const selectForCloneSuccess = createAction(
    '[ProductCategory] Select For Clone Product Categories Success',
    props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const selectForCloneFailure = createAction(
    '[ProductCategory] Select For Clone Product Categories Failed',
    props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[ProductCategory] Load Paginated ProductCategories',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[ProductCategory] Load Paginated Product Categories Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[ProductCategory] Load Paginated Product Categories Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const loadRelatedProductCategories = createAction(
    '[ProductCategory] Load Paginated Related Product Categories',
    props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadRelatedProductCategoriesSuccess = createAction(
    '[ProductCategory] Load Paginated Related Product Categories Success',
    props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const create = createAction(
  '[ProductCategory] Create Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[ProductCategory] Create Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[ProductCategory] Create Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[ProductCategory] Create Many Templates',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[ProductCategory] Create Many Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[ProductCategory] Create Many Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[ProductCategory] Update Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[ProductCategory] Update Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[ProductCategory] Update Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[ProductCategory] Update Many Templates',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[ProductCategory] Update Many Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[ProductCategory] Update Many Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateProductList = createAction(
    '[ProductCategory] Update product list for Category',
    props<{ productIds:number[], entityId: number, correlationId?: string }>()
);


export const remove = createAction(
    '[ProductCategory] Remove Template',
    props<{ entityId: number, correlationId?: string }>()
);

export const removeProductsFromCategory = createAction(
    '[ProductCategory] Remove Products from Category',
    props<{ productIds:number[], entityId: number, correlationId?: string }>()
);

export const removeProductsFromCategorySuccess = createAction(
  '[ProductCategory] Remove Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[ProductCategory] Remove Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[ProductCategory] Remove Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const deselectEntities = createAction(
  '[ProductCategory] Deselect loaded Entities'
);

export const deselectCurrentEntity = createAction(
  '[ProductCategory] Deselect Current Entity'
);

export const setCurrentEntity = createAction(
  '[ProductCategory] Set Current Entity',
  props<{ entity: Entity }>()
);

export const setCurrentEntityId = createAction(
  '[ProductCategory] Set Current Entity Id',
  props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[ProductCategory] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[ProductCategory] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const getTotalProductCategoryCount = createAction(
  '[ProductCategory] Get All Product Categories'
)

export const updateTotalProductCategoryCount = createAction(
  '[ProductCategory] Update Total Product Category Count',
  props<{ count: number }>()
);

export const getTotalProductCategoryCountFailed = createAction(
  '[ProductCategory] Get Total Product Category Count Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const createWithFile = createAction(
  '[ProductCategory] Create Product Category with file',
  props<{ entity: Entity, file: File, productIds: number[], url?: string, correlationId?: string }>()
);

export const updateWithData = createAction(
  '[ProductCategory] Update Product Category with data',
  props<{ entity: Entity, file?: File, correlationId?: string }>()
);

export const cloneWithData = createAction(
  '[ProductCategory] Clone Product Category with data',
  props<{ entity: Entity,
    templates: Template[],
    file?: File,
    correlationId?: string }>()
);

export const cloneManyWithData = createAction(
    '[ProductCategory] Clone Many Product Categories with data',
    props<{ entities: Entity[],
      templates: Template[],
      articleExtendedData?: ProductCategoryExtendedData,
      file?: File,
      correlationId?: string }>()
);

export const cloneSubCategories = createAction(
    '[ProductCategory] Clone Sub Categories with data',
    props<{
      originalProductCategoryId:number,
      productCategoryToCloneToIds: number[],
      entities: Entity[],
      templates: Template[],
      correlationId?: string
    }>()
);

export const exportCategoryWithResult = createAction(
  '[ProductCategory] Export Product Category with related data',
  props<{ productCategoryId: number, correlationId?: string }>()
);

export const checkoutProductCategory = createAction(
  '[ProductCategory] Checkout Product Category',
  props<{ entity: Entity, correlationId?: string }>()
);
export const checkoutProductCategorySuccess = createAction(
  '[ProductCategory] Checkout Product Category with returned order',
  props<{ entity: Entity, orderResult: IOrderResult, correlationId?: string }>()
);
export const checkoutProductCategoryFailure = createAction(
  '[ProductCategory] Checkout Product Category with failed request',
  props<{ entity: Entity, error: Error, correlationId?: string }>()
);

export const exportCategoryWithResultSuccess = createAction(
  '[ProductCategory] Update Total Product Category Count',
  props<{ exportFile: Blob }>()
);

export const exportCategoryWithResultFailure = createAction(
  '[ProductCategory] Export Product Category with related data Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const loadAllRootCategories = createAction(
  '[ProductCategory] Get All Related root Categories store it on allrelated categories'
)


export const loadAllRootCategoriesFailure = createAction(
  '[ProductCategory] Get All Related root Categories store it on allrelated categories Failure',
  props<{ error: Error}>()
);


export const loadAllRootCategoriesSuccess = createAction(
    '[ProductCategory] Get All Related root Categories store it on allrelated categories Success',
    props<{ result : Entity[] }>()
);

export const ProductCategoryActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  loadRelatedProductCategories,
  loadRelatedProductCategoriesSuccess,

  create,
  createWithFile,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,

  update,
  updateWithData,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  updateProductList,

  remove,
  removeSuccess,
  removeFailure,
  removeProductsFromCategory,
  removeProductsFromCategorySuccess,

  clone,
  cloneWithData,
  cloneManyWithData,
  cloneSubCategories,
  cloneSuccess,
  cloneFailure,

  checkoutProductCategory,
  checkoutProductCategorySuccess,
  checkoutProductCategoryFailure,

  setCurrentEntity,
  setCurrentEntityId,
  deselectEntities,
  deselectCurrentEntity,

  setPaginationSettings,
  setSortSettings,

  loadSingleIfNecessary,

  getTotalProductCategoryCount,
  updateTotalProductCategoryCount,
  getTotalProductCategoryCountFailed,
  exportCategoryWithResult,
  exportCategoryWithResultSuccess,
  exportCategoryWithResultFailure,

  loadIfNecessary,
  loadSuccessIfNecessary,
  loadFailureIfNecessary,

  clear,
  clearPage,

  clearNavigationHierarchy,
  removeNavigationHierarchy,
  addNavigationHierarchy,
  removeLastNavigationHierarchy,

  sliceNavigationHierarchy,
  loadNavigationHierarchy,
  loadNavigationHierarchySuccess,
  loadNavigationHierarchyFailure,

  selectForClone,
  selectForCloneFailure,
  selectForCloneSuccess,
  clearSelectedForClone,

  loadRelatedHierarchy,
  loadRelatedHierarchySuccess,
  loadRelatedHierarchyFailure,

  getRootCategory,
  getRootCategorySuccess,
  getRootCategoryFailure,

  loadAllRootCategories,
  loadAllRootCategoriesFailure,
  loadAllRootCategoriesSuccess,
};
