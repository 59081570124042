import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import { Observable, Subject} from 'rxjs';
import {TemplateTag, TemplateTag as Entity} from './template-tag.model';
import {TemplateTagActions as Actions} from './template-tag.actions';
import {TemplateTagSelectors as Selectors} from "./template-tag.selectors";
import {AppState} from "src/app/store/app-store.state";
import {generateUniqueId} from "src/app/store/app-store.functions";
import {PaginatedResult} from "src/app/store/app-store-entity.service";
import {filter, first} from "rxjs/operators";
import { Criteria } from "src/app/store/app-store.types";
import {IPage, ITableSort} from "src/app/store/app-store.interfaces";

@Injectable({providedIn: 'root'})
export class TemplateTagFacade {
  all$: Observable<Entity[]> = this.store.pipe(select(Selectors.selectAll));
  isLoading$: Observable<boolean> = this.store.pipe(select(Selectors.isLoading));
  hasError$: Observable<Error | undefined> = this.store.pipe(select(Selectors.hasErrors));
  current$: Observable<Entity | undefined> = this.store.pipe(select(Selectors.currentEntity));
  currentId$: Observable<number | undefined> = this.store.pipe(select(Selectors.currentEntityId));
  totalPageable$: Observable<number> = this.store.pipe(select(Selectors.totalPageable));
  currentPage$: Observable<IPage> = this.store.pipe(select(Selectors.currentPage));
  currentSort$: Observable<ITableSort | undefined> = this.store.pipe(select(Selectors.currentSort));

  private _refreshPage = new Subject<void>();
  refreshPage$: Observable<void> = this._refreshPage.asObservable();

  constructor(private store: Store<AppState>) {
  }

  deselect(): void {
    this.store.dispatch(Actions.deselectEntities());
  }

  selectByTemplateCategoryId(templateCategoryId: number, isRequired?: boolean): Observable<TemplateTag[] | undefined> {
     return this.store.pipe(select(Selectors.getTagValuesByCategoryId(templateCategoryId, isRequired)));
  }

  refreshPage(): void {
    this._refreshPage.next();
  }

  setPaginationSettings(page: IPage): void {
    this.store.dispatch(Actions.setPaginationSettings({page}));
    this.refreshPage();
  }

  setSortSettings(tableSort: ITableSort): void {
    this.store.dispatch(Actions.setSortSettings({tableSort}));
  }

  setCurrentId(entityId: number): void {
    this.store.dispatch(Actions.setCurrentEntityId({entityId}));
  }

  getByTemplateCategoryId(templateCategoryId: number, isRequired?:boolean) {
    this.store.dispatch(Actions.getTemplateTagsByCategoryId({templateCategoryId, isRequired}));
  }

  loadPage(pageNumber: number, pageSize: number, criteria: Criteria = undefined): void {
    this.store.dispatch(Actions.loadPage({criteria, page: {pageNumber, pageSize}}));
  }

  loadPageWithResult(pageNumber: number, pageSize: number, criteria: Criteria = undefined): Observable<PaginatedResult<Entity>> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.loadPage({page: {pageNumber, pageSize}, criteria, correlationId: correlationId}));
    return this.store.select(Selectors.findByCorrelationId<PaginatedResult<Entity>>(correlationId))
      .pipe(filter((x) => !!x));
  }

  load(productCategoryId: number, filter?: string, page?: IPage): void {
    this.store.dispatch(Actions.load({productCategoryId, filter, page}));
  }

  loadWithResult(productCategoryId: number, filterValue?: string, page?: IPage): Observable<Entity[]> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.load({productCategoryId, filter:filterValue, page, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  loadSingleWithResult(entityId: number): Observable<Entity> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.loadSingleIfNecessary({entityId, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x), first());
  }


  create(entity: Entity): void {
    this.store.dispatch(Actions.create({entity}));
  }

  createWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.create({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  update(entity: Entity): void {
    this.store.dispatch(Actions.update({entity, correlationId: generateUniqueId()}));
  }

  updateWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.update({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  remove(entityId: number): void {
    this.store.dispatch(Actions.remove({entityId}));
  }

  removeWithResult(entityId: number): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.remove({entityId, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  clone(entity: Entity): void {
    this.store.dispatch(Actions.clone({entity}));
  }

  cloneWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.clone({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  getById(entityId: number): Observable<Entity | undefined> {
    return this.store.pipe(select(Selectors.selectById(entityId)));
  }
}
