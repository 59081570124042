import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {TemplateTag} from "./template-tag.model";
import {IPage, IResultWithPageInfo} from "../app-store.interfaces";
import {map} from "rxjs/operators";
import {PaginatedResult} from "../app-store-entity.service";

@Injectable({providedIn: 'root'})
export class TemplateTagService {
  constructor(private http: HttpClient) {
  }

  clone(id: number, cloneProducts: boolean): Observable<TemplateTag> {
      return this.http.get<TemplateTag>(
          `${environment.rootUrl}/template-tag/clone/${id}/${cloneProducts}`
      );
  }

  get(templateCategoryId: number, filter?: string, page?: IPage):  Observable<IResultWithPageInfo<TemplateTag>> {
    return this.http.get<PaginatedResult<TemplateTag>>(
      `${environment.rootUrl}/template-tag?productCategoryId=${templateCategoryId}&filter=${filter}&pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}`
    ).pipe(
      map(({items, totalCount}) => <IResultWithPageInfo<TemplateTag>>({
        pageInfo: {
          page: page,
          totalCount
        },
        entities: items
      }))
    );
  }

  getByTemplateCategoryId(templateCategoryId: number, isRequired?:boolean): Observable<TemplateTag[]> {
    return this.http.get<TemplateTag[]>(
      `${environment.rootUrl}/template-tag/get-tags-by-category-id/${templateCategoryId}` + (!!isRequired ? `?isRequired=true` : ''),
    );
  }
}
