import {createSelector} from '@ngrx/store';
import {productCategoryEntityAdapter as EntityAdapter, ProductCategoryState as EntityState} from "./product-category.state";
import {ProductCategory as Entity} from './product-category.model';
import {AppState} from "src/app/store/app-store.state";

export const selectState = (state: AppState) => state.productCategory;

export const selectAll = createSelector(selectState, EntityAdapter.getSelectors().selectAll);

export const selectById = (entityId: number) =>
  createSelector(selectAll, (entities: Entity[]) => entities.find(p => p.id === entityId));

export const isLoading = createSelector(selectState, (state: EntityState) => state.isLoading);
export const isLoadingCurrent = createSelector(selectState, (state: EntityState) => state.isLoadingCurrent);
export const isEmpty = createSelector(selectState, (state: EntityState) => Object.keys(state.entities).length === 0);

export const hasErrors = createSelector(selectState, (state: EntityState) => state.error);

export const findByCorrelationId = <T>(correlationId: string) => createSelector(
  selectState,
  (state: EntityState) => <T>state.history.find(h => h.correlationId === correlationId)?.payload
);

export const currentEntityId = createSelector(selectState, (state: EntityState) => state.currentEntityId);

export const currentEntity = createSelector(selectState, (state: EntityState) => {
  return state.currentEntityId
    ? state.entities[state.currentEntityId]
    : undefined
});

export const currentPageEntities = createSelector(selectState, (state: EntityState) => {
  return state.currentPageEntities
    ? state.currentPageEntities.map(id => state.entities[id]).filter(y => y !== undefined && y.id !== state.currentEntityId)
    : undefined;
});

export const currentRelatedEntities = createSelector(
    selectState, (state: EntityState) => state.currentRelatedEntities);

export const relatedHierarchy = createSelector(selectState, (state: EntityState) => state.relatedHierarchy);
export const navigationHierarchy = createSelector(selectState, (state: EntityState) => state.navigationHierarchy);
export const totalPageable = createSelector(selectState, (state: EntityState) => state.currentPage.totalCount);
export const currentPage = createSelector(selectState, (state: EntityState) => state.currentPage.page);
export const currentSort = createSelector(selectState, (state: EntityState) => state.currentSort);
export const totalCount = createSelector(selectState, (state: EntityState) => state.totalCount);
export const exportFile = createSelector(selectState, (state: EntityState) => state.exportFile);

export const currentRelatedEntitiesTotalPageable = createSelector(selectState, (state: EntityState) => state.currentRelatedEntitiesCurrentPage.totalCount);
export const currentRelatedEntitiesCurrentPage = createSelector(selectState, (state: EntityState) => state.currentRelatedEntitiesCurrentPage.page);
export const currentRelatedEntitiesCurrentSort = createSelector(selectState, (state: EntityState) => state.currentRelatedEntitiesCurrentSort);

export const currentProductCategoriesToCloneIsLoading = createSelector(selectState, (state: EntityState) => state.currentCategoriesToCloneIsLoading);
export const currentProductCategoriesToCloneTotalPageable = createSelector(selectState, (state: EntityState) => state.currentCategoriesToCloneCurrentPage.totalCount);
export const currentProductCategoriesToClonePage = createSelector(selectState, (state: EntityState) => state.currentCategoriesToCloneCurrentPage.page);
export const currentProductCategoriesToClone = createSelector(selectState, (state: EntityState) => state.currentCategoriesToClone);

export const currentRootCategoryId = createSelector(selectState, (state: EntityState) => state.currentRootCategoryId);
export const currentRootCategoryIdIsLoading = createSelector(selectState, (state: EntityState) => state.currentRootCategoryIdIsLoading);

export const allRootCategories = createSelector(selectState, (state: EntityState) => state.allRootEntities);

export const ProductCategorySelectors = {
  selectAll,
  selectById,
  isLoading,
  isLoadingCurrent,
  hasErrors,
  findByCorrelationId,
  currentEntity,
  currentEntityId,
  totalPageable,
  currentPage,
  currentSort,
  totalCount,
  exportFile,
  isEmpty,
  currentPageEntities,
  navigationHierarchy,
  currentRelatedEntities,
  currentRelatedEntitiesCurrentPage,
  currentRelatedEntitiesCurrentSort,
  currentRelatedEntitiesTotalPageable,
  currentProductCategoriesToCloneTotalPageable,
  currentProductCategoriesToClonePage,
  currentProductCategoriesToClone,
  currentProductCategoriesToCloneIsLoading,
  relatedHierarchy,
  currentRootCategoryId,
  currentRootCategoryIdIsLoading,
  allRootCategories,
};
