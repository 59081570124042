import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ProductCategory} from "./product-category.model";
import {IOrderResult, IPage, IResultWithPageInfo} from "../app-store.interfaces";
import {ProductCategoryExtendedData} from "../../interfaces/ExtendedData";
import {Template} from "../template/template.model";
import {Criteria} from "../app-store.types";
import {PaginatedResult} from "../app-store-entity.service";
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class ProductCategoryService {
  constructor(private http: HttpClient) {
  }

  getTotalCount(): Observable<number> {
    return this.http.get<number>(
      `${environment.rootUrl}/product-category/getall`
    )
  }

  clone(id: number, cloneProducts: boolean): Observable<ProductCategory> {
    return this.http.get<ProductCategory>(
      `${environment.rootUrl}/product-category/clone/${id}/${cloneProducts}`
    );
  }

  exportProductCategoryJson(id: number): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json')
    return this.http.get<Blob>(`${environment.rootUrl}/product-category/export-json/${id}`,{ headers: headers });
  }

    selectForClone = (page: IPage, criteria?: Criteria): Observable<IResultWithPageInfo<ProductCategory>> => {
        const call = this.http.post<PaginatedResult<ProductCategory>>(`${environment.rootUrl}/product-category/select-for-clone`,
            {filter: criteria, pageNumber: page.pageNumber, pageSize: page.pageSize});

        return call.pipe(
            map(({items, totalCount}) => ({
                pageInfo: {
                    page: page,
                    totalCount
                },
                entities: items
            }))
        );
    }
    checkoutProductCategory = (entity: ProductCategory): Observable<IOrderResult> =>
    this.http.get<IOrderResult>(`${environment.rootUrl}/product-category/checkout/${entity.id}/${encodeURIComponent(entity.name)}`);

  updateWithRelatedData(
    productCategory: ProductCategory,
    formFile?: File,)
    : Observable<ProductCategory> {

    const formData = new FormData()
    if (formFile != undefined)
    formData.append('formFile', formFile);

    if (productCategory.extendedData !== undefined)
        formData.append('extendedData', JSON.stringify(productCategory.extendedData));

    for (let key in productCategory) {
      formData.append(`ProductCategory.${key}`, (productCategory as any)[key]);
    }

    return this.http.post<ProductCategory>(`${environment.rootUrl}/product-category/update-with-data/`, formData);
  }

  cloneWithRelatedData(
    productCategory: ProductCategory,
    templates: Template[],
    formFile?: File)
    : Observable<ProductCategory> {

    const formData = new FormData()
    if (formFile != undefined)
    formData.append('formFile', formFile);
    formData.append('templates', JSON.stringify(templates));

      if (productCategory.extendedData !== undefined)
          formData.append('extendedData', JSON.stringify(productCategory.extendedData));

      for (let key in productCategory) {
      formData.append(`ProductCategory.${key}`, (productCategory as any)[key]);
    }

    return this.http.post<ProductCategory>(`${environment.rootUrl}/product-category/clone-with-data/`, formData);
  }

    cloneManyWithRelatedData(
        productCategories: ProductCategory[],
        templates: Template[],
        extendedData?: ProductCategoryExtendedData,
        formFile?: File)
        : Observable<ProductCategory> {

        const formData = new FormData()
        if (formFile != undefined)
            formData.append('formFile', formFile);
        formData.append('templates', JSON.stringify(templates));
        formData.append('productCategories', JSON.stringify(productCategories));

        if (extendedData !== undefined)
        formData.append('extendedData', JSON.stringify(extendedData));

        return this.http.post<ProductCategory>(`${environment.rootUrl}/product-category/clone-many-with-data/`, formData);
    }

  cloneSubCategories(
    originalProductCategoryId: number,
    productCategoryToCloneToIds:number[],
    productCategories: ProductCategory[],
    templates: Template[])
    : Observable<ProductCategory> {

    const formData = new FormData()

    formData.append("originalProductCategoryId",originalProductCategoryId.toString())
    formData.append('productCategoryIds', JSON.stringify(productCategoryToCloneToIds));
    formData.append('subCategoriesToClone', JSON.stringify(productCategories));
    formData.append('templates', JSON.stringify(templates));

    return this.http.post<ProductCategory>(`${environment.rootUrl}/product-category/clone-many-subcategories/`, formData);
  }

  loadNavigationHierarchy(productCategoryId: number): Observable<ProductCategory[]> {
    return this.http.get<ProductCategory[]>(`${environment.rootUrl}/product-category/load-navigation-hierarchy/${productCategoryId}`);
  }

  loadRelatedHierarchy(productCategoryId: number): Observable<ProductCategory[]> {
    return this.http.get<ProductCategory[]>(`${environment.rootUrl}/product-category/load-related-categories/${productCategoryId}`);
  }

  getRootCategoryId(productCategoryId: number): Observable<number> {
    return this.http.get<number>(`${environment.rootUrl}/product-category/get-root-category-id/${productCategoryId}`);
  }

  createWithFile(
    productCategory: ProductCategory,
    formFile: File,
    productIds: number[],
    url?: string)
    : Observable<ProductCategory> {

    const formData = new FormData()
    if (url != undefined)
    formData.append('url', url);

    formData.append('formFile', formFile);
    formData.append('productIds', JSON.stringify(productIds));

      if (productCategory.extendedData !== undefined)
          formData.append('extendedData', JSON.stringify(productCategory.extendedData));

    for (let key in productCategory) {
      formData.append(`ProductCategory.${key}`, (productCategory as any)[key]);
    }

    return this.http.post<ProductCategory>(`${environment.rootUrl}/product-category/create-with-file/`, formData);
  }

    removeProductsFromCategory(productIds: number[], productCategoryId: number):Observable<ProductCategory> {
        return this.http.post<ProductCategory>(`${environment.rootUrl}/product-category/remove-products-from-category/`, {
            productIds,
            productCategoryId
        });
    }

    updateProductCategoryProductList(productIds: number[], productCategoryId: number):Observable<ProductCategory> {
        return this.http.post<ProductCategory>(`${environment.rootUrl}/product-category/update-products`, {
            productIds,
            productCategoryId
        });
    }

  loadAllRootCategories(){
    return this.http.get<ProductCategory[]>(`${environment.rootUrl}/product-category/get-all-root-categories/`);
  }
}
