import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {ProductBase as Entity} from "./product-base.model";
import {IPageInfo, IResultWithPageInfo, ITableSort} from "../app-store.interfaces";


export interface ProductBaseState extends EntityState<Entity> {
  entities: { [id: number]: Entity };
  selectForEditorEntities: number[] | undefined;
  selectForEditorEntitiesCurrentPageEntities: number[] | undefined;
  selectForEditorEntitiesCurrentPage: IPageInfo;
  selectForEditorEntitiesCurrentSort: ITableSort | undefined;
  ids: number[];
  isLoading: boolean;
  isLoadingEditor$: boolean;
  error: Error | undefined;
  currentPageEntities: number[] | undefined;
  currentPage: IPageInfo;
  currentSort: ITableSort | undefined;
  currentEntityIds: number[] | undefined;
  currentEntityId: number | undefined;
  history: { correlationId: string, payload: Entity | Entity[] | number | number[] | IResultWithPageInfo<Entity> }[];
  totalCount: number;
}

export const productEntityAdapter: EntityAdapter<Entity> = createEntityAdapter<Entity>(
  {
    selectId: (product: Entity) => product.id
  }
);

export const initialState: ProductBaseState = productEntityAdapter.getInitialState({
  entities: [],
  selectForEditorEntitiesCurrentPageEntities: undefined,
  selectForEditorEntities: undefined,
  selectForEditorEntitiesCurrentPage: { page: { pageNumber: 0, pageSize: 50 }, totalCount: 0},
  selectForEditorEntitiesCurrentSort: undefined,
  ids: [],
  isLoading: false,
  isLoadingEditor$: false,
  error: undefined,
  currentPageEntities: undefined,
  currentPage: { page: { pageNumber: 0, pageSize: 50 }, totalCount: 0},
  currentSort: undefined,
  currentEntityId: undefined,
  currentEntityIds: undefined,
  history: [],
  totalCount: 0,
});
