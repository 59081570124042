import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {ProductCategory as Entity} from "./product-category.model";
import {IOrderResult, IPageInfo, IResultWithPageInfo, ITableSort} from "../app-store.interfaces";


export interface ProductCategoryState extends EntityState<Entity> {
  entities: { [id: number]: Entity };
  allRootEntities : Entity[],
  ids: number[];
  isLoading: boolean;
  isLoadingCurrent: boolean;
  error: Error | undefined;
  navigationHierarchy: Entity[];
  relatedHierarchy: Entity[],

  currentRootCategoryId: number | undefined,
  currentRootCategoryIdIsLoading: boolean;

  currentCategoriesToCloneIsLoading: boolean,
  currentCategoriesToClone: Entity[] | undefined,
  currentCategoriesToCloneCurrentPage: IPageInfo;
  currentCategoriesToCloneCurrentSort: ITableSort | undefined;

  currentPage: IPageInfo;
  currentPageEntities: number[] | undefined,
  currentSort: ITableSort | undefined;
  currentEntityId: number | undefined,
  currentRelatedEntities: Entity[] | undefined,
  currentRelatedEntitiesCurrentPage: IPageInfo;
  currentRelatedEntitiesCurrentSort: ITableSort | undefined;
  history: { correlationId: string, payload: Entity | Entity[] | number | number[] | IResultWithPageInfo<Entity> | IOrderResult }[];
  totalCount: number;
  exportFile: Blob | undefined;
}

export const productCategoryEntityAdapter: EntityAdapter<Entity> = createEntityAdapter<Entity>(
  {
    selectId: (product: Entity) => product.id
  }
);

export const initialState: ProductCategoryState = productCategoryEntityAdapter.getInitialState({
  entities: [],
  allRootEntities : [],
  ids: [],
  isLoading: false,
  isLoadingCurrent: false,
  error: undefined,
  navigationHierarchy: [],
  relatedHierarchy: [],
  currentPageEntities: undefined,
  currentPage: { page: { pageNumber: 0, pageSize: 50 }, totalCount: 0},
  currentSort: undefined,
  currentEntityId: undefined,
  currentRelatedEntities: undefined,
  currentRelatedEntitiesCurrentPage: { page: { pageNumber: 0, pageSize: 50 }, totalCount: 0},
  currentRelatedEntitiesCurrentSort: undefined,
  history: [],
  totalCount: 0,
  exportFile: undefined,
  currentCategoriesToCloneIsLoading: false,
  currentCategoriesToClone: undefined,
  currentCategoriesToCloneCurrentPage: { page: { pageNumber: 0, pageSize: 50 }, totalCount: 0},
  currentCategoriesToCloneCurrentSort: undefined,

  currentRootCategoryId: undefined,
  currentRootCategoryIdIsLoading: false,
});
