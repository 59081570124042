import {createReducer, on} from "@ngrx/store";
import {productCategoryEntityAdapter as EntityAdapter, initialState} from "./product-category.state";
import {
  ProductCategoryActions as Actions
} from "./product-category.actions";

export const productCategoryReducer = createReducer(
  initialState,

  on(Actions.clear, state => {
    return EntityAdapter.removeAll({
      ...state,
      isLoading: false,
      error: undefined,
      currentPage: {page: {pageNumber: 0, pageSize: 10}, totalCount: 0}
    });
  }),

  on(Actions.load, Actions.loadSingle, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId
  })),

  on(Actions.loadSuccess, (state, {entities, correlationId}) => {
      if (!correlationId)
        return EntityAdapter.setAll(entities, {
          ...state,
          isLoading: false,
          error: undefined,
        });

      return EntityAdapter.setAll(entities, {
        ...state,
        isLoading: false,
        error: undefined,
        correlationId,
        history: [...state.history, {correlationId, payload: entities}]
      });
    }
  ),

  on(Actions.loadSingleSuccess, (state, {entity, correlationId}) => {
      if (!correlationId)
        return EntityAdapter.setOne(entity, {
          ...state,
          isLoading: false,
          error: undefined,
        });

      return EntityAdapter.setOne(entity, {
        ...state,
        isLoading: false,
        error: undefined,
        correlationId,
        history: [...state.history, {correlationId, payload: entity}]
      });
    }
  ),


  on(Actions.loadRelatedProductCategories, (state, {correlationId}) => ({
    ...state,
    isLoadingCurrent: true,
    correlationId,
  })),

  on(Actions.loadRelatedProductCategoriesSuccess, (
    state,
    {resultWithPageInfo, correlationId}
  ) => {
    if (!correlationId)
      return({
        ...state,
        isLoadingCurrent: false,
        error: undefined,
        currentRelatedEntities: resultWithPageInfo.entities,
        currentRelatedEntitiesCurrentPage: resultWithPageInfo.pageInfo,
      });

    return ({
      ...state,
      isLoadingCurrent: false,
      error: undefined,
      currentRelatedEntities: resultWithPageInfo.entities,
      history: [...state.history, {correlationId, payload: resultWithPageInfo}],
      currentRelatedEntitiesCurrentPage: resultWithPageInfo.pageInfo,
    });
  }),

  on(Actions.loadFailure, Actions.loadSingleFailure,(state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.cloneWithData, (state, {correlationId}) => ({
    ...state,
    currentCategoriesToCloneIsLoading: true,
    correlationId,
  })),

  on(Actions.cloneManyWithData, (state, {correlationId}) => ({
    ...state,
    currentCategoriesToCloneIsLoading: true,
    correlationId,
  })),

  on(Actions.cloneSubCategories, (state, {correlationId}) => ({
    ...state,
    currentCategoriesToCloneIsLoading: true,
    correlationId,
  })),

  on(Actions.cloneSuccess, (state, {entity, correlationId}) => {
      if (!correlationId)
        return EntityAdapter.addOne(entity, {
          ...state,
          currentCategoriesToCloneIsLoading: false,
          error: undefined,
        });

      return EntityAdapter.addOne(entity, {
        ...state,
        currentCategoriesToCloneIsLoading: false,
        error: undefined,
        correlationId,
        history: [...state.history, {correlationId, payload: entity}]
      });
    }
  ),

  on(Actions.cloneFailure, (state, {error, correlationId}) => ({
    ...state,
    currentCategoriesToCloneIsLoading: false,
    error,
    correlationId,
  })),


  on(Actions.create, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.createWithFile, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.createSuccess, (state, {entity, correlationId}) => {
      if (!correlationId)
        return EntityAdapter.addOne(entity, {
          ...state,
          isLoading: false,
          error: undefined,
        });

      return EntityAdapter.addOne(entity, {
        ...state,
        isLoading: false,
        error: undefined,
        correlationId,
        history: [...state.history, {correlationId, payload: entity}]
      });
    }
  ),

  on(Actions.createFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.createMany, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.createManySuccess, (state, {entities, correlationId}) => {
    if (!correlationId)
      return EntityAdapter.addMany(entities, {
        ...state,
        isLoading: false,
        error: undefined,
      });

    return EntityAdapter.addMany(entities, {
      ...state,
      isLoading: false,
      error: undefined,
      correlationId,
      history: [...state.history, {correlationId, payload: entities}],
    });
  }),

  on(Actions.createManyFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),


  on(Actions.update, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.updateProductList, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.updateSuccess, (state, {entity, correlationId}) => {
      if (!correlationId)
        return EntityAdapter.updateOne(
          {id: entity.id, changes: entity},
          {
            ...state,
            isLoading: false,
            error: undefined,
          });

      return EntityAdapter.updateOne(
        {id: entity.id, changes: entity},
        {
          ...state,
          isLoading: false,
          error: undefined,
          correlationId,
          history: [...state.history, {correlationId, payload: entity}]
        });
    }
  ),

  on(Actions.updateFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.updateMany, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.updateManySuccess, (state, {entities, correlationId}) => {
    if (!correlationId)
      return EntityAdapter.updateMany(
        entities.map(entity => ({id: entity.id, changes: entity})),
        {
          ...state,
          isLoading: false,
          error: undefined,
        });

    return EntityAdapter.updateMany(
      entities.map(entity => ({id: entity.id, changes: entity})),
      {
        ...state,
        isLoading: false,
        error: undefined,
        correlationId,
        history: [...state.history, {correlationId, payload: entities}],
      });
  }),

  on(Actions.updateManyFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.remove, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),


  on(Actions.removeSuccess, (state, {entity, correlationId}) => {
    if (!correlationId)
      return EntityAdapter.removeOne(entity.id, {
        ...state,
        isLoading: false,
        error: undefined,
      });

    return EntityAdapter.removeOne(entity.id, {
      ...state,
      isLoading: false,
      error: undefined,
      correlationId,
      history: [...state.history, {correlationId, payload: entity}]
    });
  }),

  on(Actions.removeProductsFromCategory, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.removeProductsFromCategorySuccess, (state, {entity, correlationId}) => {
    if (!correlationId)
      return EntityAdapter.setOne(entity, {
        ...state,
        isLoading: false,
        error: undefined,
      });

    return EntityAdapter.setOne(entity, {
      ...state,
      isLoading: false,
      error: undefined,
      correlationId,
      history: [...state.history, {correlationId, payload: entity}]
    });
  }),

  on(Actions.removeFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.loadPage, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.selectForClone, (state, {correlationId}) => ({
    ...state,
    currentCategoriesToCloneIsLoading: true,
    correlationId,
  })),

  on(Actions.loadPageSuccess, (state, {resultWithPageInfo, correlationId}) => {
    if (!correlationId)
      return EntityAdapter.setAll(resultWithPageInfo.entities, {
        ...state,
        isLoading: false,
        error: undefined,
        currentPageEntities: resultWithPageInfo.entities.map(entity => entity.id),
        currentPage: resultWithPageInfo.pageInfo,
      });

    return EntityAdapter.setAll(resultWithPageInfo.entities, {
      ...state,
      isLoading: false,
      error: undefined,
      currentPageEntities: resultWithPageInfo.entities.map(entity => entity.id),
      currentPage: resultWithPageInfo.pageInfo,
      history: [...state.history, {correlationId, payload: resultWithPageInfo}],
    });
  }),

  on(Actions.selectForCloneSuccess, (state, {resultWithPageInfo, correlationId}) => {
    if (!correlationId)
      return {
        ...state,
        currentCategoriesToCloneIsLoading: false,
        error: undefined,
        currentCategoriesToClone: resultWithPageInfo.entities,
        currentCategoriesToCloneCurrentPage: resultWithPageInfo.pageInfo,
      };

    return ({
      ...state,
      currentCategoriesToCloneIsLoading: false,
      error: undefined,
      correlationId,
      currentCategoriesToClone: resultWithPageInfo.entities,
      currentCategoriesToCloneCurrentPage: resultWithPageInfo.pageInfo,
      history: [...state.history, {correlationId, payload: resultWithPageInfo.entities}],
    });
  }),

  on(Actions.loadPageFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.selectForCloneFailure, (state, {error, correlationId}) => ({
    ...state,
    currentCategoriesToCloneIsLoading: false,
    error,
    correlationId,
  })),
  on(Actions.setCurrentEntityId, (state, {entityId}) => ({
    ...state,
    currentEntityId: entityId,
  })),

  on(Actions.deselectCurrentEntity, (state) => ({
    ...state,
    currentEntityId: undefined,
  })),

  on(Actions.updateTotalProductCategoryCount, (state, { count}) => ({
    ...state,
    totalCount: count
  })),

  on(Actions.exportCategoryWithResultSuccess, (state, { exportFile}) => ({
    ...state,
    exportFile: exportFile
  })),

  on(Actions.checkoutProductCategory, (state, { entity, correlationId }) => ({
    ...state,
    isLoading: true,
    correlationId,
    entity,
  })),

  on(Actions.checkoutProductCategorySuccess, (state, { entity, orderResult, correlationId }) => {
    if (!correlationId)
      return {
        ...state,
        isLoading: false,
      };

    return ({
      ...state,
      isLoading: false,
      history: [...state.history, {correlationId, payload: orderResult}],
    });
  }),

  on(Actions.checkoutProductCategoryFailure, (state, { entity, error, correlationId }) => ({
    ...state,
    isLoading: false,
    error: error,
    correlationId,
    entity
  })),

  on(Actions.clearPage, (state, {}) => ({
    ...state,
    currentPageEntities: initialState.currentPageEntities,
    currentPage: initialState.currentPage,
    exportFile: initialState.exportFile,
  })),

  on(Actions.setPaginationSettings, (state, {page}) => ({
    ...state,
    currentPage: { totalCount: state.currentPage?.totalCount, page},
  })),

  on(Actions.loadNavigationHierarchy, Actions.loadRelatedHierarchy, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.loadRelatedHierarchySuccess, (state, {entities, correlationId}) => {
    if (!correlationId)
      return {
        ...state,
        isLoading: false,
        error: undefined,
        relatedHierarchy: entities
      };

    return ({
      ...state,
      isLoading: false,
      error: undefined,
      correlationId,
      relatedHierarchy: entities,
      history: [...state.history, {correlationId, payload: entities}],
    });
  }),

  on(Actions.loadNavigationHierarchySuccess, (state, {entities, correlationId}) => {
    if (!correlationId)
      return {
        ...state,
        isLoading: false,
        error: undefined,
        navigationHierarchy: entities
      };

    return ({
      ...state,
      isLoading: false,
      error: undefined,
      correlationId,
      navigationHierarchy: entities,
      history: [...state.history, {correlationId, payload: entities}],
    });
  }),

  on(Actions.loadNavigationHierarchyFailure, Actions.loadRelatedHierarchyFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.getRootCategory, (state, {correlationId}) => ({
    ...state,
    currentRootCategoryIsLoading: true,
    correlationId,
  })),

  on(Actions.getRootCategorySuccess, (state, {productCategoryId}) => ({
    ...state,
    currentRootCategoryId: productCategoryId
  })),

  on(Actions.getRootCategoryFailure, (state, {correlationId}) => ({
    ...state,
    currentRootCategoryIsLoading: false,
    correlationId,
  })),

  on(Actions.clearNavigationHierarchy, (state, {}) => ({
    ...state,
    navigationHierarchy: initialState.navigationHierarchy,
  })),

  on(Actions.clearSelectedForClone, (state, {}) => ({
    ...state,
    currentCategoriesToClone: undefined,
    currentCategoriesToCloneCurrentPage: { page: { pageNumber: 0, pageSize: 50 }, totalCount: 0},
    currentCategoriesToCloneCurrentSort: undefined
  })),


  on(Actions.loadAllRootCategories, (state, {}) => ({
    ...state,
    isLoading: true
  })),

  on(Actions.loadAllRootCategoriesSuccess, ( state, { result } ) => ({
      ...state,
      isLoading: false,
      error: undefined,
      allRootEntities : result,
  })),

  on(Actions.loadAllRootCategoriesFailure,(state, {error}) => ({
    ...state,
    isLoading: false,
    allRootEntities : [],
    error
  })),
);
